@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
  --background-color: #363635;
  --background-color-shade: #9c9c9c;
  --background-color-shade-outline: #666666;
  --nav-background: #505050;
  --dark-blue-text: #b5babe;
  --dark-brown: #131001;
  --link-text: #b37e2f;
  --link-text-light: #e0a434;
  --footer-link-text-light: #73afea;
  --light-gray: #9fb4ca;
  --lighter-gray: #cbd6e3;
  --navigation: #b3c5c9;
  --navigation-hover: #5b6567;
  --navigation-focus: #dbad2c;
  --scrollbar-color: #3a2302;
  --scrollbar-background: #776e4e;
}