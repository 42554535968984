.about-text {
  font-size: 15px;
  font-weight: 500;
  white-space: pre-wrap;
  color: var(--dark-blue-text);
  margin-bottom: 24px;
  line-height: 24px;
}

.about-hr {
  width: 50%;
}

.about-section {
  margin-top: 5px;
  margin-bottom: 20px;
  margin: 5px 10% 10px 10%;
  display: flex;
  flex-direction: column;
}

.about-section-heading {
  margin: 0px 10%;
}

.center-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40%;
  margin-right: 40%;
}