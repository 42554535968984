.inline-link {
  text-decoration: underline;
  color:rgb(18, 50, 90);
}

#venmo {
  margin-top: 30px;
  max-height: 164px;
}

.venmo-text {
  width: 75%;
}

.venmo-container {
  display: flex;
}

.heading {
  color: var(--dark-blue-text);
}

.faqs {
  margin: 25px 20%;
  background-color: var(--background-color-shade);
  padding: 20px;
  border-radius: 10px;
  border: 2px solid var(--background-color-shade-outline);
}

.text-align {
  text-align: center;
}

.faq-descript-container {
  padding-bottom: 5px;
}

.faq-descript {
  font-size: 16px;
  font-weight: 500;
  white-space: pre;
}

@media screen and (max-width: 700px) {
  .faqs {
    margin: 20px 10%;
  }
}