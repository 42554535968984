* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-family);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section-padding {
    padding: 4rem;
  }

  .section-margin {
    padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section-padding {
    padding: 4rem 2rem;
  }

  .section-margin {
    padding: 4rem 2rem;
  }
}


body {
  background: var(--background-color);
  /* overflow: overlay; */
  /* make header image minimum height instead of overlay */
  overflow-y: scroll;
  /* must use this to avoid navigation items moving around when going to pages with different heights */
}

::-webkit-scrollbar {
  width: 15px;
  height: 14px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 5px;
  border: 2px solid var(--scrollbar-background);
}

::-webkit-scrollbar-button:single-button {
  background-color: var(--scrollbar-background);
  display: block;
  border-style: solid;
  background-repeat: no-repeat;
  background-position: center center;
  height: 12px;
}

::-webkit-scrollbar-button:single-button:horizontal {
  display: none;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border: 7px solid var(--scrollbar-background);
  border-color: transparent transparent var(--scrollbar-color) transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent var(--lighter-gray) transparent;
  background-color: var(--link-text-light);
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border: 7px solid var(--scrollbar-background);
  border-color: var(--scrollbar-color) transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: var(--lighter-gray) transparent transparent transparent;
  background-color: var(--link-text-light);
}

/* horizontal */

::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border: 7px solid var(--scrollbar-background);
  border-color: transparent transparent var(--scrollbar-color) transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent var(--lighter-gray) transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:increment {
  border: 7px solid var(--scrollbar-background);
  border-color: var(--scrollbar-color) transparent transparent transparent;
}

::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: var(--lighter-gray) transparent transparent transparent;
}


a {
  color: unset;
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
}

.nav-background {
  color: var(--nav-background);
}

.gradient-text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-background-fill-color: transparent;
}

.section-padding {
  padding: 4rem 6rem;
}

.section-margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.link-text {
  color: var(--link-text);
}

.link-text:hover {
  color: var(--link-text-light);
}

hr {
  width: 70%;
  border: 0.5px solid var(--light-gray);
  background: var(--light-gray);
  margin: auto;
  border-radius: 1px;
  height: 1px;
}

.subtitle, h1, h2, h3, h4 {
  color: var(--dark-blue-text);
}

.subtitle, h1, h2 {
  padding: 15px 10px;
}

h3, h4 {
  padding: 10px 5px;
}

.content {
  min-height: 85vh;
}