.home-text {
  font-size: 15px;
  font-weight: 500;
  white-space: pre-wrap;
  color: var(--dark-blue-text);
  margin-bottom: 24px;
  line-height: 24px;
}

.home-hr {
  width: 50%;
}

.home-section {
  margin-top: 5px;
  margin-bottom: 20px;
  margin: 5px 10% 10px 10%;
  display: flex;
  flex-direction: column;
}

.home-section-heading {
  margin: 0px 10%;
}

.nyt-article-screen {
  width: 80%;
  margin: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 5px var(--nav-background);
  margin-bottom: 20px;
  border: 1px solid var(--scrollbar-color);
}

.nyt-article-screen:hover {
  border: 1px solid var(--scrollbar-background);
  filter: sepia(100%) saturate(200%) brightness(95%) hue-rotate(140deg);
  cursor: pointer;
  transition: 0.25s;
}