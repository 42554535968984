.navbar {
  display: flex;
  align-items: center;
  /* background: var(--nav-background); */
  background: url(../../assets/nav-background.png);
  background-repeat: no-repeat;
  background-size:cover;
  height: 8vh;
  min-height: 60px;
  max-height: 80px;
  box-shadow: inset 0px -5px 5px rgba(179, 179, 179, 0.8);
}

.navbar-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0px;
  padding-right: 100px;
  min-width: 50vw;
}

.center-fill {
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  justify-content: center;
}

.nav-item {
  font-weight: 700;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  min-height: 60px;
  max-height: 80px;
}

.nav-item-color {
  color: var(--navigation);
}

.nav-item-color-focus {
  color: var(--navigation-focus);
}

.nav-home {
  font-weight: 700;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  min-height: 60px;
  max-height: 80px;
  min-width: 100px;
}

.nav-item-div {
  overflow: hidden;
  line-height: 8vh;
  height: 8vh;
  white-space: pre;
  min-height: 60px;
  max-height: 80px;
  display: flex;
  align-items: center;
}

.nav-home-div {
  overflow: hidden;
  line-height: 8vh;
  height: 8vh;
  white-space: pre;
  min-width: 100px;
  min-height: 60px;
  max-height: 80px;
  display: flex;
  align-items: center;
}

.nav-home .nav-item {
  justify-content: center;
}

.nav-item:hover {
  /* background: var(--navigation-hover); */
  box-shadow: inset 0px 0px 10px rgba(179, 179, 179, 0.8);
}

.nav-home:hover {
  /* background: var(--navigation-hover); */
  box-shadow: inset 0px 0px 10px rgba(179, 179, 179, 0.8);
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
  margin-right: 19px;
  color: var(--navigation);
}

.navbar-menu-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: left;
  background-color: var(--nav-background);
  padding: 15px;
  position: absolute;
  top: 35px;
  right: max(8px, 1vh);
  margin-top: max(16px, 2.5vh);
  min-width: 210px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  z-index: 5;
}

.navbar-menu-container p {
  margin: 1rem 0;
}

@media screen and (max-width: 600px) {
  .navbar-links-container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }

  .nav-item {
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    border-radius: 5px;
    justify-content: start;
    height: 60px;
    padding: 0px 20px;
    align-items: center;
  }

  .nav-item-div {
    height: 60px;
  }

  .navbar-menu-container p {
    margin: 0;
  }
}