.courts {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.courts-paragraph {
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
}

.schedule {
  max-width: 550px;
}

.schedule-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 550px) {
  .schedule {
    max-width: 300px;
  }
}