.webcam-text {
  color: var(--dark-blue-text);
  margin: 20px 0px;
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}

.iframe-container {
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
}

.iframe-crop {
  position: relative;
  width: 70%;
  height: 850px;
  top: -180px;
}